<template>
  <div class="courses-wrapper h-100">
    <!-- courses -->
    <b-row class="courses-list-wrapper">
      <b-overlay
        :show="$store.state.app.loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <b-col
        v-for="(item, key) in coursesData"
        :key="key"
        md="4"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link
            :to="{ name: 'courses-preview', params: { id: item.id } }"
            class="image-container"
            :disabled="item.iStatus === '0'"
          >
            <b-button
              v-if="item.crtName"
              v-b-tooltip.hover.left
              :title="item.crtName"
              variant="link"
              class="certificate"
            >
              <b-avatar
                variant="success"
                size="32"
              >
                <feather-icon
                  icon="AwardIcon"
                />
              </b-avatar>
            </b-button>
            <div
              class="top"
              :class="item.iStatus === '2' ? 'hovered' : ''"
            >
              <div
                v-if="item.iStatus !== '1'"
                class="top-inner p-2"
                :class="item.iStatus === '0' ? 'lock' : 'check'"
              >
                <small
                  v-html="item.Status"
                  class="top-text"
                />
                <div class="top-icon">
                  <feather-icon
                    v-if="item.iStatus === '0'"
                    icon="LockIcon"
                    size="38"
                    class="cursor-pointer"
                  />
                </div>
                <div class="top-icon">
                  <feather-icon
                    v-if="item.iStatus === '2'"
                    icon="CheckIcon"
                    size="38"
                    class="cursor-pointer"
                  />
                </div>
              </div>
              <div
                class="right-bottom"
              >
                <small>{{ item.sCountLess }}</small>
              </div>
            </div>
            <b-carousel
              v-if="item.c_imgPreview && item.c_imgPreview.length"
              id="carousel-fade"
              style="text-shadow: 0px 0px 2px #000"
              fade
              indicators
              :interval="item.iStatus === '0' ? 0 : 3000"
            >
              <b-carousel-slide
                v-for="(src, key) in item.c_imgPreview"
                :key="key"
                :img-src="server + src"
              ></b-carousel-slide>
            </b-carousel>
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'courses-preview', params: { id: item.id } }"
                class="courses-title-truncate text-body-heading"
                :disabled="item.iStatus === '0'"
              >
                {{ item.c_name }}
              </b-link>
            </b-card-title>
            <!-- <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  href="javascript:void(0)"
                  size="24"
                  :text="avatarText(item.UsName)"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">от</small>
                <small>
                  <b-link
                    class="text-body"
                  >{{ item.UsName }}</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ item.c_date }}</small>
              </b-media-body>
            </b-media>
            <div>
              <small
                v-if="item.n_datePost"
                class="text-body"
              >
                <span>Активность с {{ item.n_datePost }}</span>
                <span v-if="item.n_datePostEnd"> по {{ item.n_datePostEnd }}</span>
              </small>
            </div>
            <div class="py-25">
              <span>Публикация для: </span><span>{{ item.inpt_name }}</span>
            </div> -->
            <b-card-text class="courses-content-truncate">
              <div v-html="item.c_descr" />
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-if="coursesData && coursesData.length"
        cols="12"
      >
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <!--/ courses -->
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardTitle,
  BCardBody, BLink, BPagination, BAvatar,
  VBTooltip,
  // BMedia, BMediaAside, BMediaBody,
  BButton,
  BOverlay, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { kFormatter, avatarText } from '@core/utils/filter'
import store from '@/store'
import coursesStoreModule from '../coursesStoreModule'
import useCoursesList from './useCoursesList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BLink,
    BPagination,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    BAvatar,
    BButton,
    BCarousel,
    BCarouselSlide,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    kFormatter,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-courses'
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, coursesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const server = process.env.VUE_APP_SERVER
    const api = process.env.VUE_APP_API

    const {
      coursesData,
      perPage,
      currentPage,
      total,
      fetchCourses,
      deleteCourses,
      updateCoursesStatus,

      coursesStatuses,
      xparam1,
    } = useCoursesList()

    fetchCourses()

    return {
      coursesData,
      perPage,
      currentPage,
      total,

      avatarText,

      deleteCourses,
      updateCoursesStatus,

      coursesStatuses,
      xparam1,

      server,
      api,
    }
  },
}
</script>
<style scoped>
  .top-right {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 2;
  }
  .courses-list-wrapper {
    position: relative;
  }
  .image {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  .top {
    transition: .5s ease;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: left;
    border-top-left-radius: calc(0.428rem - 1px);
    border-top-right-radius: calc(0.428rem - 1px);
    overflow: auto;
    z-index: 2;
  }

  .image-container {
    position: relative;
  }

  .image-container:hover .image {
    opacity: 0.3;
  }

  .top-text {
    opacity: 0;
  }

  .image-container:hover .top-text {
    opacity: 1;
  }

  .top .top-inner {
    color: white;
    height: 100%;
  }

  .top .top-inner.lock {
    background-color: #000;
  }

  .top .top-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 38px;
    width: 38px;
  }

  .right-bottom {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: #fff;
    background: #000;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 3px;
  }

  .top .top-inner.check {
    background-color: #2371b8;
  }
  .top.hovered:hover .top-inner {
    display: none;
  }

  .certificate {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 3;
    padding: 0;
  }
</style>
