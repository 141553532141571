import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useCourses() {
  const coursesData = ref([])
  const perPage = ref(10)
  const coursesStatuses = [
    { value: '0', label: 'Не опубликовано' },
    { value: '1', label: 'Опубликовано' },
    { value: '1000', label: 'Все' },
  ]
  const xparam1 = ref('1000')
  const total = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const fetchCourses = () => {
    store.dispatch('app-courses/fetchCourses', {
      xparam1: xparam1.value,
      currentPage: currentPage.value,
      offset: perPage.value * (currentPage.value - 1),
      perPage: perPage.value,
      searchQuery: searchQuery.value,
      sortby: sortBy.value,
      isSortDirDesc: isSortDirDesc.value,
    })
      .then(response => {
        const { data } = response.data
        coursesData.value = data.rows
        total.value = data.paging.total || 0
      })
      .catch(() => {
        coursesData.value = undefined
      })
  }

  const deleteCourses = id => {
    store.dispatch('app-courses/deleteCourses', id).then(res => {
      if (res) {
        fetchCourses()
      }
    })
  }

  const updateCoursesStatus = item => {
    store.dispatch('app-courses/updateCoursesStatus', item).then(res => {
      if (res) {
        fetchCourses()
      }
    })
  }

  watch([xparam1, currentPage], () => {
    fetchCourses()
  })

  return {
    coursesData,
    perPage,
    currentPage,
    total,
    searchQuery,
    sortBy,
    isSortDirDesc,

    coursesStatuses,
    xparam1,

    fetchCourses,
    deleteCourses,
    updateCoursesStatus,
  }
}
